import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4 col-lg-6">
          <div className="lead text-sm-center text-md-right">
            <h3 className="seo-span seo-strong text-muted">
            Kant-en-klare webtoepassingen.
            </h3>
            <br />
            <span className="text-service">
            De beste software-kwaliteit voor&nbsp;
              <strong className="span-regular">offshore prijzen</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              Bekijk ons aanbod!
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="seo-p">
            Onze <h4 className="seo-span">ontwikkelaars</h4> zijn erg bekwaam en hebben veel ervaring bij het maken van ongebruikelijke 
            webtoepassingen voor onze klanten uit de hele wereld. We bieden back-end modernisatie en 
            cloudverbindingen. Bovendien is ons team in staat de bestaande front-end te actualiseren, 
            wat zorgt voor een verbetering van de snelheid van de software. &nbsp;
            
            <h4 className="seo-span">De ontwikkelaars</h4>&nbsp;van Zaven zijn altijd efficiënt, kosteneffectief en gericht op de behoeften van de klant.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
