import React from 'react'
import Link from '../../common/Link'

const ServiceWebTags = () => (
  <section className="service-web bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Web development</span>
            <br />
            <strong>software team</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          We zorgen er altijd voor dat je project toegewezen wordt aan personen die daar
           het het best bij passen, omdat we vinden dat een goed gekozen team garant staat
            voor succes. Elke projectgroep bestaat uit ontwikkelaars, ontwerpers, 
            UI-experts en front-end deskundigen. <Link to="/about/team/">{' '}Maak kennis met ons team </Link>voor meer informatie.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Oplossingen van webtoepassingen</span>
            <br />
            voor ondernemingen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="seo-p">
            Onze
            <h2 className="seo-span">service voor de aanmaak van webtoepassingen</h2>
            helpt je je marketingdoelen te bereiken ongeacht de sector waarin je werkt: 
            e-learning, ERP, BPA, sociale media, e-commerce, kansspelen, innovatie, 
            gezondheidstechnologie of leefstijl.
          </div>
          <ul className="list-unstyled list-inline list-badges">
            <li>
              <span className="badge badge-light">.NET</span>
            </li>
            <li>
              <span className="badge badge-light">.NET MVC</span>
            </li>
            <li>
              <span className="badge badge-light">Ruby on Rails</span>
            </li>
            <li>
              <span className="badge badge-light">HTML5</span>
            </li>
            <li>
              <span className="badge badge-light">ExpressJS</span>
            </li>
            <li>
              <span className="badge badge-light">Zend</span>
            </li>
            <li>
              <span className="badge badge-light">ASO.NET</span>
            </li>
            <li>
              <span className="badge badge-light">Angular JS</span>
            </li>
            <li>
              <span className="badge badge-light">Prototype testing</span>
            </li>
            <li>
              <span className="badge badge-light">QA testing</span>
            </li>
            <li>
              <span className="badge badge-light">Responsive web design</span>
            </li>
            <li>
              <span className="badge badge-light">Support & Maintenance</span>
            </li>
            <li>
              <span className="badge badge-light">Static websites</span>
            </li>
            <li>
              <span className="badge badge-light">
                Mobile-friendly websites
              </span>
            </li>
            <li>
              <span className="badge badge-light">Service integrations</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceWebTags
