import React from 'react'
import Link from '../../common/Link'

const ServiceWebRequirementCta = () => (
  <section className="service-web bg-dark py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="lead text-center">
            <strong>
            Een volledig functionele webtoepassing in amper 4 weken?&nbsp;
            </strong>
            <br />
            <div className="d-inline text-muted">
            Ons 
              <h3 className="seo-span">proces bij het ontwerpen en aanmaken van webtoepassingen</h3>
              is aanzienlijk sneller omdat ons team op een efficiënte en soepele manier werkt.
            </div>
            <br />
            <Link to="/estimate-app-cost/" className="btn btn-info block-xs m-3">
            Bespreek uw eis
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebRequirementCta
