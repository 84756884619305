import React from 'react'
import Link from '../../common/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const ServiceWebOffer = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">De outsourcing van de bouw</span>
            <br />
            van webtoepassingen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          Waarom loont het om projecten te outsourcen aan Zaven? 
          Omdat we met succes samenwerken met klanten uit verschillende 
          werelddelen en omdat we weten hoe we hen tijdens het hele proces 
          moeten begeleiden: Van het uitwerken van een visie, consultatie, 
          prototypering, het ontwerpen van de UX, het testen van QA, het bouwen 
          van de toepassing, uitvoering, RWD en integratie tot onderhoud en support.
          </p>
          <p>
          Ons near-sourcing team garandeert efficiëntie en een hoog rendement met offshore prijzen. 
          Raadpleeg onze <Link to="/services/poland-software-outsourcing/">
          outsourcingsgids
            </Link>
             &nbsp;om te weten te komen welk model het best bij je past.
          </p>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-sm-12 col-md-4">
          <div className="seo-h2 text-sm-center text-md-right">
            <span className="text-muted text-regular">
              <strong className="seo-regular">De belofte van het bedrijf </strong> en 
            </span>
            <br />

            <h3 className="seo-span">
              <strong>de productiecyclus van de software</strong>
            </h3>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          In ons bedrijf weten we dat tevredenheid en vertrouwen aan de 
          basis liggen van elk langdurig zakelijk partnerschap. Om er 
          zeker van te zijn dat onze samenwerking vlot verloopt, hebben 
          we de beste methoden aangenomen op het gebied van het maken en uitvoeren van software.
          </p>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Volledig functionele en gebruiksvriendelijke oplossingen
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Lage kosten en geweldige prijs-kwaliteitsverhouding
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Hoge adaptatie van de gebruiker
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <h4 className="seo-span">Agile ontwikkelingsproces</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebOffer
